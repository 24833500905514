/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
:root {
  --primary-color: #148888;
  --background-color: #363636;
  --text-color: #fecb00;

  --primary-btn-background-color: #148888;
  --primary-btn-text-color: #ffffff;
  --primary-btn-hover-background-color: #148888;
  --primary-btn-hover-text-color: #ffffff;
}
.btn {
  padding: 10px, 24px, 10px, 24px !important;
  border-radius: 22px !important;
  font-weight: 500;
}
.btn-primary {
  color: var(--primary-btn-hover-text-color) !important;
  background-color: var(--primary-btn-hover-background-color) !important;
  border-color: var(--primary-btn-hover-background-color) !important;
}

.integration-menu .mdc-list-item__primary-text {
  color: #fff !important;
}

.mat-typography h4 {
  line-height: 1.5 !important;
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 8px;
  display: inline-block !important;
}
.accounts-list {
  .mdc-list-item__primary-text {
    color: #363636 !important;
  }
  .mdc-list-item:hover .mdc-list-item__primary-text {
    color: #363636 !important;
  }
}
.md-drppicker td.active,
.md-drppicker td.active:hover {
  background-color: #22225e !important;
}
.md-drppicker .ranges ul li button.active {
  background-color: #22225e !important;
}
.md-drppicker .btn {
  color: var(--primary-btn-hover-text-color) !important;
  background-color: var(--primary-btn-hover-background-color) !important;
  border-color: var(--primary-btn-hover-background-color) !important;
  min-width: 100px;
  border-radius: 22px !important;
  font-weight: 500;
}

.md-drppicker .btn-default {
  color: #363636 !important;
  background-color: #fff !important;
  border-color: #363636 !important;
  min-width: 100px;
  border-radius: 22px !important;
  font-weight: 500;
  margin-right: 10px;
}
.show-hide-btn {
  .btn-secondary {
    border-radius: 0px 5px 5px 0px !important;
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    z-index: 0 !important;
  }
  z-index: 0 !important;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}
//set password css
.password-requirements {
  font-weight: 500;
}
.password-requirement {
  margin: 0;
  padding: 0;
  list-style-type: none !important;
}
.password-requirement-valid {
  background: url(assets/images/tick.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #3a7d34;
}
.password-requirement-invalid {
  background: url(assets/images/cross.png) no-repeat 0 50%;
  padding-left: 22px;
  line-height: 24px;
  color: #ec3f41;
}
.required {
  color: red !important;
}